import config from "config/main-config.json";
import ImageExtractor from "utils/Extractors/images";
import "./RomeOutdoor.scss";
import {
    playersImg,
    timeImg,
    getRoom,
    ScrollToTopOnPathChange,
} from "helpers/Description.helpers";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NavigateToHomePage from "components/Navigate/Navigate";

export const Description = () => {
    const { room } = useParams();
    const Room = getRoom(`outdoor/${room}`, config);
    if (!Room) return <NavigateToHomePage />;
    const { t } = useTranslation();
    const roomImg = ImageExtractor(`default/svgs/${Room.svg}`);
    const difficulty = ImageExtractor(`default/svgs/difficulty-map.svg`);
    const romeVideo = ImageExtractor(`rome_new_illuminati.mp4`);
    const videoLogo = ImageExtractor(`rome_prologo.mp4`);
    const botLogo = ImageExtractor(`default/svgs/bot_logo.svg`);
    const credit = ImageExtractor(`default/svgs/credit-card.svg`);
    const posterHorizontal = ImageExtractor("poster_horizontal.jpg");
    const posterVertical = ImageExtractor("poster_vertical.jpg");

    ScrollToTopOnPathChange();

    const RoomTitle = () => (
        <>
            <div
                id='outdoor-room'
                className='row container'>
                <p>AVVENTURA NOTTURNA NELLA CITTÀ DI ROMA</p>
                {Room.svg && (
                    <img
                        loading='lazy'
                        src={roomImg}
                        className='img-fluid'
                        alt={Room.name}
                    />
                )}
                <br />
                <h1 className='outdoor-title'>
                    {t(Room.name)}
                    <br />
                    {Room.name_span && (
                        <div id='name_span'>{t(Room.name_span)}</div>
                    )}
                </h1>
            </div>
        </>
    );

    return (
        <>
            <div className='header-image'>
                <RoomTitle />{" "}
            </div>

            <div
                className='col-lg-12 outdoor-box'
                id='description-box'>
                <div className='col-lg-12 text-center'>
                    <h2 className='outdoor-location'>
                        <span> {t(Room.outdoor_location)}</span>
                    </h2>
                    <div className='row'>
                        <div className='col-md-12 col-12 col-xs-6 text-center'>
                            <div className='row'>
                                <div
                                    id='category2'
                                    className='my-0 mx-auto d-block text-center'>
                                    <img
                                        loading='lazy'
                                        src={playersImg}
                                        alt='player image'
                                        className='img-fluid outdoor-page-icon'
                                    />
                                    <div className='text-center'>
                                        <p className='text-[#434343] font-semibold relative text-[1.3em] whitespace-nowrap m-0 p-2 max-w-full'>
                                            Giocatori
                                        </p>
                                        <p className='text-[#d61934] font-bold text-2xl my-0 mx-auto'>
                                            2-8
                                        </p>
                                    </div>
                                </div>
                                <div
                                    id='category2'
                                    className='my-0 mx-auto d-block text-center'>
                                    <img
                                        loading='lazy'
                                        src={timeImg}
                                        alt={Room.time}
                                        className='img-fluid outdoor-page-icon'
                                    />
                                    <div className='text-center'>
                                        <p className='sub-text'>
                                            Durata di gioco
                                        </p>
                                        <p className='text-[#d61934] font-bold text-2xl my-0 mx-auto'>
                                            180`
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className='my-0 mx-auto d-block text-center'
                                    id='category2'>
                                    <img
                                        loading='lazy'
                                        className='img-fluid outdoor-page-icon'
                                        src={difficulty}
                                        alt='difficulty'
                                    />
                                    <div className='text-center text-capitalize'>
                                        <p className='sub-text'>Difficolta</p>
                                        <p className='text-[#d61934] font-bold text-2xl my-0 mx-auto'>
                                            90%
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    <p
                        id='outdoor-description'
                        className='rome-p'>
                        Il Cammino degli Illuminati è una meravigliosa avventura
                        di 3 ore che giocherete di notte in giro per la città di
                        Roma. Anche se non è una classica Escape Room, il fatto
                        di aver già affrontato gli enigmi nelle nostre stanze,
                        vi ha in qualche modo preparato a questa nuova sfida. Se
                        invece non avete ancora una certa dimestichezza con gli
                        enigmi, vi consigliamo di giocare qualche stanza in più
                        per essere poi pronti al Cammino degli Illuminati.
                    </p>
                    <h3 className='underline'> STORIA </h3>
                    <p
                        id='outdoor-description'
                        className='rome-p'>
                        Esiste un antica società segreta meglio conosciuta come
                        L &apos; Ordine degli Illuminati &apos;, un &apos;
                        organizzazione millenaria nata con l &apos;unico scopo
                        di tramandare il sapere servendosi di una selezionata e
                        ristretta cerchia di fedeli. Il Gran Maestro dell
                        &apos;Ordine ha deciso di inserire nuovi discepoli all
                        &apos;interno del gruppo e per far questo vuole
                        sottoporre a chiunque ne abbia il coraggio, quella che è
                        senza dubbio la più antica prova iniziatica dell &apos;
                        Ordine: <br />
                        <br /> Il Cammino degli Illuminati <br />
                        <br /> Si tratta di un percorso segreto che si articola
                        all &apos;interno della città di Roma e del quale in
                        pochi ne conoscono il punto di partenza. Per la prima
                        volta nella storia, Il Gran Maestro ha reso noto l
                        &apos; Enigma che cela l &apos; inizio del Cammino.{" "}
                        <br />
                        <br /> Chiunque sarà in grado di portare a compimento il
                        percorso potrà quindi dimostrare di essere degno e
                        meritevole di entrare a far parte dell&apos; Ordine
                        degli Illuminati. Ricordatevi che se mai riuscirete a
                        trovare il modo di iniziare il Cammino, da quel momento
                        avrete unicamente 3 ore di tempo per portarlo a termine.{" "}
                        <br />
                        <br /> Illuminati, il Gran Maestro vi sta aspettando...
                    </p>

                    <h3 className='underline'>
                        TRAILER - IL CAMMINO DEGLI ILLUMINATI
                    </h3>

                    <div id='video'>
                        <video
                            className='video_1 my-0 mx-auto'
                            width='80%'
                            height='auto'
                            poster={posterHorizontal}
                            controls
                            controlsList='nodownload'
                            playsInline>
                            <source
                                src={romeVideo}
                                type='video/mp4'
                            />
                        </video>
                    </div>

                    <br />
                    <p
                        id='outdoor-description'
                        className='rome-p'>
                        Per affrontare il Cammino degli Illuminati sarà
                        fondamentale comprendere il punto di inizio della vostra
                        avventura. Qui di seguito, il primo enigma sottopostovi
                        dal Gran Maestro. Solo risolvendolo dimostrerete di
                        essere meritevoli di affrontare la prova e comprenderete
                        quindi il luogo segreto in cui la vostra squadra si
                        dovrà recare.
                    </p>

                    <div className='row video-2-section'>
                        <div className='col-6 col-xs-6 text-center blText'>
                            Primo Enigma <br />
                            Prologo
                        </div>
                        <div className='col-6 col-xs-6'>
                            <video
                                className='video_2 -mt-8'
                                height='auto'
                                poster={posterVertical}
                                controls
                                controlsList='nodownload'
                                playsInline>
                                <source
                                    src={videoLogo}
                                    type='video/mp4'
                                />
                            </video>
                        </div>
                    </div>
                    <p
                        id='outdoor-description'
                        className='rome-p'>
                        Per poter affrontare il Cammino degli Illuminati
                        scaricate qui di seguito la nostra applicazione. Sarà lo
                        strumento grazie al quale potrete interagire con
                        quest&apos;avventura. Per giocare è necessario
                        acquistare un coupon online o presso la nostra sede di
                        Roma Trastevere
                    </p>
                    <div className='text-center logoBot '>
                        <img
                            src={botLogo}
                            className='my-0 mx-auto'
                        />
                    </div>

                    <div className='text-center'>
                        <a
                            href='https://gameovercityadventure.com/pwa/'
                            target='_blank'
                            rel='noreferrer'>
                            <button
                                type='button'
                                className='btn btn-danger btnFoo'>
                                SCARICA <br />
                                L&apos;APPLICAZIONE
                            </button>
                        </a>
                        {/* <a
                            href='https://voucher.gameovercityadventure.com/checkout-app'
                            target='_blank'
                            rel='noreferrer'>
                            <button
                                type='button'
                                className='btn btn-danger btnFoo'>
                                ACQUISTA IL <br />
                                COUPON €90,00
                            </button>{" "}
                        </a> */}
                    </div>

                    <hr
                        className='hr-rome border-t-1 border-[#da1a35]'
                        width='80%'
                        color='#da1a35'></hr>

                    <div className='container contentFoo'>
                        <div className='row imgRowFoo'>
                            <div className='col-md-4 col-xs-4 text-center'>
                                <img
                                    className='my-0 mx-auto'
                                    width='40px'
                                    src={playersImg}
                                    style={{ marginBottom: "2rem" }}
                                />
                                <p className='text-center'>2-8 persone </p>
                            </div>
                            <div className='col-md-4 col-xs-4 text-center'>
                                {" "}
                                <img
                                    width='40px'
                                    className='my-0 mx-auto'
                                    src={timeImg}
                                    style={{ marginBottom: "2rem" }}
                                />
                                <p className='text-center'>3 ore di gioco</p>
                            </div>
                            <div className='col-md-4 col-xs-4 text-center'>
                                <img
                                    width='40px'
                                    height='40px'
                                    className='my-0 mx-auto'
                                    src={credit}
                                    style={{ marginBottom: "2rem" }}
                                />
                                <p className='text-center'>90.00€</p>
                            </div>
                        </div>
                        <p className='text-center'>
                            Game Over Escape Rooms - Game Over City Adventure.
                            Tutti i diritti sono riservati.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Description;
